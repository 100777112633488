// COLOR VARS
// ==============================
$white: #ffffff;
$black: #222222;

// Buttons:
$brand-color: #e91c24;
$blue: #4483FD;
$classroom-blue:#4c85f5;

$body-color: $black;
$body-bg: #f2f2f2;

$off-white: #f6f6f6;
$lighter-gray: #f2f2f2;
$light-gray: #cccccc;
$light-text: #B6B6B6;
$gray: #767676;
$dark-text: #4a4a4a;
$dark-gray: #333333;
$darker-gray: #1a1a1a;

// BREAKPOINT VARS
// ==============================

$bp-xsmall: 360px;
$bp-small: 576px;
$bp-medium: 768px;
$bp-large: 999px;
$bp-xlarge: 1440px;

:export { bpXSmall: $bp-xsmall; bpSmall: $bp-small; bpMedium: $bp-medium; bpLarge: $bp-large; bpXLarge: $bp-xlarge; }

// CONTAINER SIZE
// ==============================

$container-large: 1770px;
$container-medium: 1280px;
$container-small: 900px;
$container-xs: 767px;

/*FIXED DIMENSIONS*/
$height-top-nav: 60px;
$height-article-nav: 30px;

/*FONTS*/
$open-sans-font-family: 'Open Sans', sans-serif;
$article-font-family: 'PT Serif', serif;
$red-pen-font: 'Almost Perfect', cursive;
$black-type-font: 'Special Elite', monospace;

$primary: $brand-color;