@import '../../../resources/css/sass/variables';
@import '../../../resources/css/sass/mixins';

.labelWrapper {
  white-space: nowrap;
}

.dropdownLabel {
  cursor: pointer;
}

.triangleContainer {
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin: 0 0.5em 0 0.9em;
}

.sideTriangle {
  display: inline-block;
  position: relative;
  top: -0.16em;
  width: 14px;
  height: 14px;
  border-right: 3px solid $light-gray;
  border-bottom: 3px solid $light-gray;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;

  @include hover-supported() {
    &:hover {
      border-color: $brand-color;
    }
  }

  &Red {
    @extend .sideTriangle;
    border-color: $brand-color;
  }

  &Black {
    @extend .sideTriangle;
    border-color: $black;
  }
}



.dropdownMenu {
  position: absolute;
  border: 1px solid lightgrey;
  background-color: white;
  text-align: left;
  width: 33%;
  max-height: calc(90vh - 60px); // minus size of toolbar
  max-width: 27em;
  min-width: 160px;
  color: black;
  border-radius: 3px;
  padding: 0px 0px;
  list-style-type: none;
  box-shadow: 1px 4px 8px #888888;
  overflow-y: scroll;
  overflow-x: hidden;

  .menuItem {
    cursor: pointer;
    display: block;
    width: 100%;
    padding: 5px 15px;
    color: $body-color;
    font-size: 0.85em;
    @include hover-supported() {
      &:hover {
      text-decoration: none;
      background-color: #F5F5F5;
      }
    }
  }

  .menuLink {
    text-decoration: none;
  }

  // &:last-child{
  //   padding-bottom: 5px;
  // }
}

@media only screen and (max-width: $bp-small) {
  .dropdownMenu {
    min-width: 220px;
  }

}