@charset 'utf-8';

@import-normalize; // create-react-app's normalizer

@import 'sass/variables'; // can override bulma's default variables

// consult Bulma Docs for customising variables https://bulma.io/documentation/customize/variables/
@import '../../../node_modules/bulma/sass/utilities/_all';
@import '../../../node_modules/bulma/sass/base/_all';
@import '../../../node_modules/bulma/sass/elements/_all';
@import '../../../node_modules/bulma/sass/form/_all';
@import '../../../node_modules/bulma/sass/components/_all';
@import '../../../node_modules/bulma/sass/grid/_all';
@import '../../../node_modules/bulma/sass/layout/_all';

@import 'sass/base';
@import 'sass/typography';
// @import 'sass/mixins';
// @import 'sass/custom';
// @import 'sass/elements';
// @import 'sass/pages';
@import 'bulma-tooltip';

html {
    font-family: "Open-Sans", $family-primary; // extends Bulma's default body font
}

// For React Notifications
.CustomNotificationPosition {
    z-index: 9998;
    position: fixed;
    overflow-y: auto;
    max-height: 100vh;
    top: 60px;
    right: 0;
    flex-direction: column;
}

// Column Animations
.slide-in-from-left {
    animation: slide-in-from-left 0.2s forwards;
    -webkit-animation: slide-in-from-left 0.2s forwards;
}

.slide-out-to-left {
    animation: slide-out-to-left 0.2s forwards;
    -webkit-animation: slide-out-to-left 0.2s forwards;
}

@keyframes slide-in-from-left {
    100% { transform: translateX(0%); opacity:1}
}

@-webkit-keyframes slide-in-from-left {
    100% { -webkit-transform: translateX(0%); opacity:1}
}

@keyframes slide-out-to-left {
    0% { transform: translateX(0%); opacity: 1}
    100% { transform: translateX(100%); opacity:0.01 }
}

@-webkit-keyframes slide-out-to-left {
    0% { -webkit-transform: translateX(0%); opacity:1}
    100% { -webkit-transform: translateX(100%); opacity:0.01;}
}
.slide-in-from-right {
    animation: slide-in-from-right 0.2s forwards;
    -webkit-animation: slide-in-from-right 0.2s forwards;
}

.slide-out-to-right {
    animation: slide-out-to-right 0.2s forwards;
    -webkit-animation: slide-out-to-right 0.2s forwards;
}

@keyframes slide-in-from-right {
    100% { transform: translateX(0%); opacity:1}
}

@-webkit-keyframes slide-in-from-right {
    100% { -webkit-transform: translateX(0%); opacity:1}
}

@keyframes slide-out-to-right {
    0% { transform: translateX(0%); opacity: 1}
    100% { transform: translateX(-100%); opacity:0.01 }
}

@-webkit-keyframes slide-out-to-right {
    0% { -webkit-transform: translateX(0%); opacity:1}
    100% { -webkit-transform: translateX(-100%); opacity:0.01;}
}
.dynamic-column-deepest-enter {
    transform: translateX(100%);
    opacity:0.01
}
.dynamic-column-deepest-enter-active {
    animation: slide-in-from-left 0.2s forwards;
    -webkit-animation: slide-in-from-left 0.2s forwards;
}
.dynamic-column-deepest-exit {
    transform: translateX(0%);
    opacity:1
}
.dynamic-column-deepest-exit-active {
    animation: slide-out-to-left 0.2s forwards;
    -webkit-animation: slide-out-to-left 0.2s forwards;
}
.dynamic-column-enter {
    transform: translateX(-100%);
    opacity:0.01;
}
.dynamic-column-enter-active {
    animation: slide-in-from-right 0.2s forwards;
    -webkit-animation: slide-in-from-right 0.2s forwards;
}
.dynamic-column-exit {
    transform: translateX(0%);
    opacity:1
}
.dynamic-column-exit-active {
    animation: slide-out-to-right 0.2s forwards;
    -webkit-animation: slide-out-to-right 0.2s forwards;
}


// Skeleton loader
@-webkit-keyframes blink {
  0% { opacity: 0.7; }
  40% { opacity: 0.9; }
  50% { opacity: 1; }
  60% { opacity: 0.9; }
  100% { opacity: 0.7; }
}
@keyframes blink {
  0% { opacity: 0.7; }
  40% { opacity: 0.9; }
  50% { opacity: 1; }
  60% { opacity: 0.9; }
  100% { opacity: 0.7; }
}

.skeleton-blink {
  -webkit-animation: blink 1s infinite;
          animation: blink 1s infinite;
}
