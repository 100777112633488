// // BASE
// // ==============================
// * {
//   margin: 0;
//   padding: 0;
// }

// html {
// box-sizing: border-box;
// font-size: 16px;
// }

// *,
// *::after,
// *::before {
//   box-sizing: inherit;
// }
// a {
//   color: $brand-color;
//   text-decoration: none;
//   outline: 0;
//   &:hover {
//     text-decoration: none;
//   }
// }
// a:visited, .alink:visited,a:hover, .alink:hover {
//   color: $brand-color;
// }
// img {
//   max-width: 100%;
// }


h1, h2 {
  color: #333;
}

h1 {
  font-size: 185%;
}

h2 {
  font-size: 150%;
}

.uv-icon{
  z-index:39 !important;
}

// p {
//   line-height: 21px;
//   opacity: 0.7;
//   margin: 4px 0px;
// }
