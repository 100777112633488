@import '../../../resources/css/sass/variables';

.tabGridContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  overflow: scroll;
  height: 50vh;
  width: 100%;
}

.tabGridContainer>div {
  display: flex;
  flex-basis: calc(50% - 40px);
  justify-content: center;
  flex-direction: column;
}

.gridContainerItem{
  margin: 20px;
}

.number {
  width: 14px;
  height: 14px;
  color: #ccc;
  font-size: 25px;
  margin-left: 20px;
}

.imageBorder {
  display: flex;
  justify-content: center;
  padding: 10px 60px;
}

.imageBorder>img {
  width: 286px;
  height: auto;
  padding: 1px;
  border: 1px solid #021a40;
}

.modalParagraph {
  width: 334px;
  padding-bottom: 33px;
  padding-left: 61px;
  font-size: .85em;
  color: #4a4a4a;
}

.or {
  float: left;
  color: #ccc;
  font-size: 18px;
  margin-left: 16px;
}

@media only screen and (max-width: $bp-medium) {
  .modalParagraph {
    width: 65vw;
    padding-bottom: 8vw;
    padding-left: 5vw;
    font-size: .75em;
  }

  .number {
    margin-left: 0;
    padding-top: 0;
    font-size: 5vw;
  }

  .gridContainerItem {
    margin: 10px;
  }

  .or {
    font-size: 3vw;
    padding-bottom: 2vh;
    margin-left: 0;
  }
}

@media only screen and (max-width: $bp-small) {
  .or {
    font-size: 4vw;
  }

  .number {
    font-size: 6vw;
    padding-top: 6px;
  }
}