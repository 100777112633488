// TYPOGRAPHY
// ==============================

@font-face {
    font-family: 'Almost Perfect';
    src: url('../../fonts/djb_almost_perfect-webfont.woff2') format('woff2'),
         url('../../fonts/djb_almost_perfect-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}



// $line-height: 1.4;
// $font-weight: 400;
// $font-size-base: 16px;
// $font-color: $body-color;
// $heading-font-color: $dark-gray;
// $font-family: 'Open Sans';


// body {
//     font-family: $font-family;
//     font-size: $font-size-base;
//     line-height: $line-height;
//     font-weight: $font-weight;
//     color: $font-color;
//     background-color: $body-bg;
// }

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//     font-family: $font-family;
//     font-weight: 700;
//     color: $heading-font-color;
//     margin: 0 0 .625rem 0;
//     letter-spacing: .03em;
// }

// .h1,
// h1 {
//     font-size: 5.375em;
// }

// .h2,
// h2 {
//     font-size: 3.625em;
// }

// .h3,
// h3 {
//     font-size: 3em;
// }

// .h4,
// h4 {
//     font-size: 2.25em;
// }

// .h5,
// h5 {
//     font-size: 1.625em;
// }

// .h6,
// h6 {
//     font-size: 1.375em;
// }

// p {
//     margin-bottom: .625rem;
// }

// .p2 {
//     font-size: 1.375em;
//     line-height: 2.0625em;
// }



// strong,
// b {
//     font-weight: 600;
// }
