@import '../../../resources/css/sass/variables';

.missionContainer {
  overflow: scroll;
  height: 45vh;
}

.postContainer {

}

.postContent {

}

.parent {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  padding-left: 59px;
  padding-right: 58px;
  padding-top: 30px;
  padding-bottom: 40px;
}

.child {

}

.postTitle {
display: flex;
justify-content: center;
color: rgb(74, 74, 74);
margin-top: 50px;
font-family: "Open Sans", sans-serif;
}

.postParagraph {
  color: rgb(74, 74, 74);
  font-family: "Open Sans", sans-serif;
  padding-top: 10px;
  padding-bottom: 25px;
  padding-left: 37px;
  padding-right: 37px;
  font-size: 16px;
  font-weight: lighter;
  opacity: 0.6;
  line-height: 23px;
}

.postParagraph>p {
  margin: 0 0;
}

@media only screen and (max-width: $bp-medium) {
  .parent > div > svg {
    width: 25vw;
  }
}