@import '../../../resources/css/sass/variables';
@import '../../../resources/css/sass/mixins';

.toolbarContainer {
  position: fixed;
  width: 100%;
  color: white;
  background-color: rgb(34, 34, 34);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 60px;
  align-items: center;
  padding: 10px 33px;
  z-index: 1;
  font-family: 'Open sans', sans-serif;
  transition: top 0.5s;

  &>div{
    flex-basis: 0;
    flex-grow: 1;
  }
}

.logoWrapper {
  // used to center logo with "beta" subscript
  position: relative;
  top: 0.2em;
}

.toolBarFlex {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  min-width: 240px;
}

.toolbarLeftSideMenu {
  @extend .toolBarFlex;
  min-width: 0px;
  justify-content: normal;
}

.toolbarRightSideMenu {
  @extend .toolBarFlex;
  min-width: 0px;
  justify-content: flex-end;
}

.toolbarLink {
  cursor: pointer;
}

// features menu styles
.featuresContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  color:white;
}

.featureHeader {
  color: inherit;
  font-weight: bold;
}

.featureItemsList {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: baseline;
  font-size: 108%;
  width: 100%;
}

.featureLink {
  text-decoration: none;
  padding: 5px;
  color: white;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  @include hover-supported() {
    &:hover {
      cursor: pointer;
      color: $brand-color;
    }
  }

  &Active {
    @extend .featureLink;
    color: $blue;
  }
}

.featureItemStyle {
  text-align: center;
  list-style-type: none;
}

.onlyMobile {
  display: none;
}

.fromTabletDown {
  display: none;
}

@media only screen and (min-width: $bp-large) {
  div.fromTabletUp {
    display: block;
  }

  span.fromTabletUp {
    display: inline;
  }

  li.fromTabletUp {
    display: list-item;
  }
}

@media only screen and (max-width: $bp-large) {
  .onlyDesktop {
    display: none;
  }
}

@media only screen and (max-width: $bp-medium) {
  .fromTabletUp {
    display: none;
  }

  div.onlyMobile {
    display: block;
  }

  span.onlyMobile {
    display: inline;
  }

  li.onlyMobile {
    display: list-item;
  }

  .featuresContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 55px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 44px;
    background-color: #222222;
  }

  .dropdownMenu {
    top: unset !important;
    bottom: 100%;
  }

  .toolbarContainer {
    padding: 10px 20px;
  }

  .toolbarLeftSideMenu,
  .toolbarRightSideMenu {
    min-width: 0px;
  }
}

@media only screen and (max-width: $bp-small) {
  .toolbarContainer>div{
    flex-grow: 0;
  }

  .featuresContainer {
    padding-right: 15%;
  }
}
