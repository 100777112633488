// @mixin transform($transforms) {
//   -moz-transform: $transforms;
//   -o-transform: $transforms;
//   -ms-transform: $transforms;
//   -webkit-transform: $transforms;
//   transform: $transforms;
// }

// @mixin transition($transition...) {
//   -moz-transition: $transition;
//   -o-transition: $transition;
//   -webkit-transition: $transition;
//   transition: $transition;
// }

@mixin hover-supported {    
    @media (hover: hover) { 
      @content;
    }
}