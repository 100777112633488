html {
  overflow: scroll;
}

.mainClass {
  min-width: 100%;
  font-family: "Open Sans", sans-serif !important;
  padding-top: 60px;
  transition: padding-top 0.5s;
  //position: relative; // added modal
}

main {
  display: flex;
  align-self: center;
  flex-direction: column;
}
